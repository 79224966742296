import img1 from "../../../assets/images/screenshots/image-1.jpeg"
import img2 from "../../../assets/images/screenshots/image-2.jpeg"
import img3 from "../../../assets/images/screenshots/image-3.jpeg"
import img4 from "../../../assets/images/screenshots/image-4.jpeg"
import img5 from "../../../assets/images/screenshots/image-5.jpeg"
import img6 from "../../../assets/images/screenshots/image-6.jpeg"
import img7 from "../../../assets/images/screenshots/image-7.jpeg"
import img8 from "../../../assets/images/screenshots/image-8.jpeg"
import img9 from "../../../assets/images/screenshots/image-9.jpeg"
import img10 from "../../../assets/images/screenshots/image-10.jpeg"
import img11 from "../../../assets/images/screenshots/image-11.jpeg"
import img12 from "../../../assets/images/screenshots/image-12.jpeg"

export const data = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img2,
]