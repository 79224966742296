import Styles from "./AllProject.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { products1 } from "./data";

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackNavbar from "../navbar/BackNavbar";


export default function AllProject() {

    const navigate = useNavigate()
    function navigationFunction(item) {
        navigate(`/project_details/${item.id}`)
    }


    return (
        <section className={`${Styles.project_container}`}    >
            <BackNavbar />
            <div
                className="px-4 mt-4"
                data-aos="zoom-out"
                data-aos-duration="2000"
            >
                <div className={`${Styles.heading}`}>
                    Our Projects
                </div>
                <p className={`${Styles.desc}`}>
                    See How We Deliver Client's Idea Into Excellent Result!
                </p>
                <div className={`${Styles.card_container}`}

                >
                    {products1 &&
                        products1?.map((item, index) => (
                            <div key={index} className={`${Styles.project_card} `} >
                                <div
                                    // data-aos="fade-up"
                                    // data-aos-duration="2000"
                                    // data-aos-anchor-placement="top-bottom"
                                    // layoutId={item.id}
                                    onClick={() => navigationFunction(item)}

                                    className={`${Styles.project_cards1} `} >
                                    <div className={`${Styles.card_body1}`}>
                                        <div className={`${Styles.mobile}`}>
                                            <img src={item.mobile} className={`${Styles.mobile_img}`} />
                                        </div>
                                        <div className={`${Styles.card_log1}`}>
                                            <img className={`${Styles.logo}`} src={item.logo} />
                                            <div className={`${Styles.card_title}`}>
                                                {item.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`px-3 py-4 ${Styles.card_footer1}`}>
                                        <div>
                                            <div className={`${Styles.footer_desc}`}>{item.title}</div>
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                            {item.tech.map((item1, index) => (<span key={index} className={`${Styles.tech_chip}`}>{item1}</span>))}
                                        </div>

                                    </div>

                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </section>
    )
}