import React, { useEffect, useState } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import  Styles from "./navbar.module.scss"
import {Outlet, Link, NavLink } from "react-router-dom";
import Logo from "../../../components/atoms/logo/Logo";

export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};
function MyNavbar() {

  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible1 = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible1);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);


  return (
    <Navbar expand="lg" sticky="top" style={{ top: visible ? '0' : '-120px' }} className={`${Styles.container}`}>
        <NavLink to="/" className={`ms-md-3`}>
      {/* <Navbar.Brand className="ps-lg-5 ms-lg-2 m-0 p-0"> */}


        <Logo />
      {/* </Navbar.Brand> */}
        </NavLink>

      <Navbar.Toggle className={`${Styles.toggle}`} aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="m-auto">
          <Link href="#home" className={Styles.navitem} onClick={() => scrollToSection("home")}>
            Home
          </Link>
          <a href="#services" className={Styles.navitem} onClick={() => scrollToSection("services")}>
            Services
          </a>
          <a href="#projects" className={Styles.navitem} onClick={() => scrollToSection("projects")}>
            Projects
          </a>
          <a href="#clients" className={Styles.navitem} onClick={() => scrollToSection("clients")}>
            Clients
          </a>
          <a href="#process" className={Styles.navitem} onClick={() => scrollToSection("process")}>
            Process
          </a>
          <a href="#elearning" className={Styles.navitem} onClick={() => scrollToSection("elearning")}>
            ELearning
          </a>
          <a href="#blog" className={Styles.navitem} onClick={() => scrollToSection("blog")}>
            Blog
          </a>
          <a href="#contact" className={Styles.navitem} onClick={() => scrollToSection("contact")}>
            Contact us
          </a>
        </Nav>
      </Navbar.Collapse>
      <Outlet />
    </Navbar>
  );
}

export default MyNavbar;
