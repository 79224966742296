import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";


const CssTextField = styled(TextField)({

  "& .MuiInputLabel-root.Mui-focused": {
    fontSize: "22px",
    // marginTop: "10px",
    // padding:"20px"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderTop: "1px solid transparent",
      borderWidth: 0,
    },
    "&:hover fieldset": {
      // borderWidth: 0,
    },
    "&.Mui-focused fieldset": {
      // borderWidth: 0,
      // borderTop:"1px solid red",
      marginTop: "3px"
    },
  },
  width: "100%",
});


const  InputField=(props)=> {

  return (
    <div>
      <CssTextField
        label={props.label}
        variant="outlined"
        InputProps={{ style: { borderBottom: "1px solid #B2BAC2", margin: "10px 0px", } }}
      />
    </div>
  )
}
export default InputField


