import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import app from "../../App.module.scss";

export default function Footer() {
  return (
    <div className={`${app.footer} container`}>
      <div className="row py-5">
        <div className="col-12 col-md-6  mb-3 mb-lg-0">
          <div className={`${app.section_title}`}></div>
          <div className={`${app.section_desc}`}>
            We are a full-Stack development firm, partnering with clients to
            bring their vision of applications and digital products to life.
          </div>
        </div>
        <div className="col-12 col-md-6  mb-3 mb-lg-0">
          <div className={`${app.section_title} my-2`}>India</div>
          <div className={`${app.section_desc}`}>
          Wholphintech  ,Ameerpet, Hyderabad. Telangana-500073.
          </div>
          <div className={`${app.cursor} mt-1 mt-md-3 d-flex ${app.align}`}>
            <div className="">
              <FaPhoneAlt />
            </div>
            <div className="mx-1" style={{ fontSize: "19px" }}>
              +91 7993 030 801 
            </div>
          </div>
        </div>
        
      </div>
      <hr />
      <div className="row py-5">
        <div className="col-12 text-center">
          <div style={{ fontSize: "18px", fontWeight: "600" }} className="">
            © 2022 All Rights Reserved - Wholphin Tech Private Limited 
          </div>
        </div>
      </div>
    </div>
  );
}
