
import Styles from "./EightSection.module.scss"
import Theme from "../../../styles/Theme.module.scss"
import { process } from "./data";

//image
import img1 from "../../../assets/images/why-wholphintech/mob-app.png"

export default function EightSection(){
    return(
       <div className={`${Styles.container} `}>
        <div className={`row p-lg-5`}>
          <div className="px-4">
            <div className={`${Theme.section_sub_box}`}>
              <div className={`${Theme.section_sub_bar}`}></div>
              <div className={`${Theme.section_sub_title}`}>Why Wholphin Tech</div>
            </div>

          </div>
          <div className={`row mt-3 ms-1`}>
            <div className={`${Theme.section_title} col-md-5  `}>
              Our Process Highlights
            </div>
            <div className={`${Theme.section_desc}  col-md-7`}>
              We have, through a series of improvisations over the years, developed
              an app development process that is optimized to help you develop and
              manage a custom mobile application without getting straddled by the
              complexities involved.
            </div>
          </div>

        </div>

        <div className={`row ${Theme.dense_bg} ${Theme.justify_b}`}>
          <div 
          
          className="col-12 col-sm-10 col-md-5 text-center mx-auto">
            <img 
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="3000"
            src={img1} alt="" className="w-75" height={"96%"} />
          </div>
          <div
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="3000"
           className={`col-11 col-md-6 ${Styles.timeline}`}>
            {process.map(({ title, txt }, index) => {
              return (
                <div key={index} className={`${Styles.process} my-4 `}>
                  <div className="ms-3">
                    <div
                    className={Styles.sub_title}
                    >
                      {title}
                    </div>
                    <div
                      className={`${Theme.section_desc}`}
                    >
                      {txt}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div> 
    )
}