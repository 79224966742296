import Styles from "./CustomeCard.module.scss"

export function CustomeCard(props) {
    const { icon, text_align, heading, description } = props
    return (
        <div className={`${Styles.box} text-md-${text_align}`} >
            <img src={icon}
                width="40px" height="40px"
                className="mb-2 mb-md-3"
            />
            <h6 className={Styles.title}>{heading}</h6>
            <p className={`${Styles.description}`}>{description}</p>
        </div>
    )
}



export function LikesCard(props) {
    const { icon, title, count } = props
    return (
        <div className={`${Styles.like_box}`}>
            <img className={Styles.icon} src={icon} />
            <p className={Styles.count}>{count}</p>
            <p className={Styles.title}>{title}</p>
        </div>
    )

}