import { fourthData } from "./data";
import Styles from "./FivthSection.module.scss";
import home from "../Index.module.scss";
import app from "../../../App.module.scss";
export default function FivthSection() {

    return (
        <section className={`${Styles.fivth_container} p-lg-5`}>
            <div
             data-aos="zoom-out"
             data-aos-duration="2000"
                className={`row p-3 ps-lg-3`}>
                <div className="row justify-content-between ps-lg-5 pb-lg-4">
                    <div className="col-12 col-md-5">
                        <div className={app.section_sub_box}>
                            <div className={` ${app.section_sub_bar}`}></div>
                            <div className={`${app.section_sub_title}`}>Why Choose Us</div>
                        </div>
                        <div className={`${app.section_title} mt-3`}>
                            The Wholphin Tech's Advantage to Software Development
                        </div>
                    </div>
                    <div className={`col-12 col-md-7 ${app.section_desc}`}>
                        At Wholphin, we are always looking to learn more - about your
                        goals, requirements, and desired impact. For us, it is not just
                        about getting an app up and running. We go beyond just delivering
                        the product to thinking about ways to getting your app to succeed in
                        achieving your vision and end goals. Here's why you can trust us to
                        go the extra mile when it comes to managing your app requirements:
                    </div>
                </div>

                {fourthData.map(({ img, txt, desc }, index) => {
                    return (
                        <div
                            className={`${index > 2 && index < 6 && "d-lg-none"
                                } col-12 col-lg-4 mx-auto my-2 p-lg-4 `}
                            key={index}
                        >
                            <div className={`${Styles.fivth_card} ${home.card_shadow} `} >
                                <div>
                                    <img src={img} alt="" className={`${Styles.card_img} `} />
                                </div>
                                <div className={`${Styles.card_title}`}> {txt}</div>
                                {/* its vissible up to medium screens */}
                                <div
                                    className={`${app.section_desc} mx-2 text-start d-lg-none mt-3`}
                                >
                                    {desc}
                                </div>
                                {/* its vissible large screens */}
                                <div
                                    className={`${(index + 1) % 2 === 0 && `${app.purple}`} 
                                    ${Styles.fivth_card_content
                                        }   d-none d-lg-flex`}
                                >
                                    {desc}
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="col-12 col-lg-8 mx-auto d-none d-md-block my-3">
                    <div className={`${Styles.app_idea} pt-3 px-5 rounded`}>
                        <div
                            className={`${Styles.idea_heading}`}
                        >
                            Let's bring your app idea to life
                        </div>
                        <div className={`d-flex ${app.justify_b}`}>
                            <div className="my-2">

                                <div className="mt-5"
                                >
                                    <div
                                        className={`${home.btn_effect_2} px-4 py-2 ${app.cursor} overflow-hidden`}
                                    >
                                        Get In Touch
                                    </div >
                                </div>

                            </div>
                            <div className="">
                                <img src="./images/svg/cta.svg" alt="" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>{" "}
            </div>
        </section>
    )
}  
