
import {SiAndroid,SiApachecordova, SiFlutter,
   SiIonic, SiApple,SiKotlin,SiReact, SiSwift,
   SiRubyonrails, SiAngularjs,
   SiFirebase,SiRealm,SiSqlite,SiGooglechrome
  } from "react-icons/si"
// import {FaJava,FaNodeJs, FaPhp, FaPython, FaLaravel} from "react-icons/fa"
import {DiDart, DiDatabase} from "react-icons/di"
import {BiLogoSpringBoot} from "react-icons/bi"
import { FaHtml5, FaCss3Alt, FaNodeJs,FaPhp  } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { SiTypescript, SiExpress, SiMysql  } from "react-icons/si";
import { TbBrandReactNative,TbBrandFramer } from "react-icons/tb";
import { IoLogoElectron, IoDesktop } from "react-icons/io5";
import {CgWebsite} from "react-icons/cg";

import {TiCloudStorageOutline} from "react-icons/ti";








export const languages=[

  {icon: FaHtml5, name:"HTML5"},
  {icon: FaCss3Alt, name:"CSS3"},
  {icon: IoLogoJavascript, name:"JS"},
  {icon: SiTypescript , name:"TS"},
  {icon: FaNodeJs , name:"Node Js"},
  {icon: SiExpress , name:"Express Js"},
  {icon: SiMysql, name:"My Sql"},
  {icon: FaPhp, name:"PHP"},
]
export const frameWorks=[
  {icon: SiReact, name: "React JS",},
  {icon: TbBrandReactNative, name: "React Native",},  
  {icon: SiAngularjs, name: "Angular js",},
  {icon: IoLogoElectron, name: "Electron",},
  {icon: SiApachecordova, name: "Cordova",},
  {icon: TbBrandFramer, name: "Framer Motion",},

]


export const platform=[
  { icon:SiAndroid, name:"Android"  },
  { icon:SiApple, name:"IOS"  },
  { icon:CgWebsite, name:"Web"  },
  { icon:IoDesktop, name:"Desktop"  },
  { icon:SiGooglechrome, name:"PWA"  },
  { icon:TiCloudStorageOutline, name:"Cloud"  },
]

// export const dataBase=[
//   {icon: SiFirebase, name: "Firebase",},
//   {icon: SiRealm, name: "Realm",},
//   {icon: SiSqlite, name: "SQLite",},
//   {icon: DiDatabase, name: "Core Data",},
 
// ]







// export const frontend = [
//     { icon: SiAndroid, name: "Android", language: "frontend" },
//     { icon: SiApachecordova, name: "Cordova", language: "frontend " },
//     { icon: SiFlutter, name: "Flutter", language: "frontend" },
//     { icon: SiIonic, name: "Ionic", language: "frontend" },
//     { icon: SiApple, name: "IOS", language: "frontend" },
//     { icon: SiKotlin, name: "Kotlin", language: "frontend" },
//     { icon:SiReact, name: "React", language: "frontend" },
//     { icon: SiSwift, name: "Swift", language: "frontend" },

//   ];

//   export const backend = [
 
//     { icon: FaJava, name:"Java", language: "backend" },
//     { icon: FaNodeJs, name:"Node Js", language: "backend" },
//     { icon: FaPhp, name:"Php", language: "backend" },
//     { icon: SiRubyonrails, name:"Ruby Rails", language: "backend" },
//     { icon: FaPython, name:"Python", language: "backend" },
//     { icon: FaLaravel, name:"Laravel", language: "backend" },
   
//   ];