import { Routes, Route, useLocation } from "react-router-dom";
import ChildBlog from "../Pages/home/blogs/ChildBlog";
import ProjectLandingPage from "../Pages/home/projects/ProjectLandingPage";
import AllProject from "../Pages/home/projects/AllProject";
import LandingPage from "../Pages/home/LandingPage";
import AOSWrapper from "../Wraper";
import { useEffect } from "react";
const RoutesComponent =()=>{
  const { pathname } = useLocation();

  // useEffect(() => {
  //   console.log("App component mounted");
  //   window.scrollTo(0, 0);
  // }, [pathname]);
  return(
    <Routes>
        <Route path="/" exact element={  <AOSWrapper><LandingPage /> </AOSWrapper>} />
        <Route path="/projects" element={
            <AOSWrapper>
              <AllProject />
            </AOSWrapper>
          }  />
        <Route path="/blog/:id"  element={<AOSWrapper><ChildBlog /> </AOSWrapper>}/>
        <Route path="/project_details/:id"  element={<AOSWrapper><ProjectLandingPage /></AOSWrapper>}/>
      </Routes>
      )
}

export default RoutesComponent