import { Image } from "react-bootstrap"
import img1 from "../../../assets/images/logo-orange-dark.png"
import Styles from "./Logo.module.scss"
const Logo=()=>{
    return(
        <div className={Styles.logo}>
            {/* <img  src={img1} /> */}
            <Image src={img1} />
        </div>
    )
}

export default Logo