import { Link, NavLink, Outlet, useNavigate } from "react-router-dom"
import Styles from "./BackNavbar.module.scss"
import MainNav from "./navbar.module.scss"
import { Image, Nav, Navbar } from "react-bootstrap"
import { useEffect, useState } from "react"
import { Button } from "@mui/material"
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import Logo from "../../../components/atoms/logo/Logo"

const BackNavbar = () => {
    const navigate = useNavigate()
    const navigateBack = () => navigate(-1)

    useEffect(()=>{},[navigate])
    // const [prevScrollPos, setPrevScrollPos] = useState(false);
    // const [visible, setVisible] = useState(true);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const currentScrollPos = window.pageYOffset;
    //         const visible1 = prevScrollPos > currentScrollPos;

    //         setPrevScrollPos(currentScrollPos);
    //         setVisible(visible1);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [prevScrollPos]);



    

    return (
        <Navbar sticky="top" 
        // style={{ top: visible ? '0' : '-120px' }} 
        className={`${MainNav.container}`}>
            <Nav className={Styles.backnav}>
                <Button onClick={() => navigateBack()} variant="outlined" className={Styles.back_button} >
                  
                    <div className={`me-2 ${Styles.navitem}`}>
                        <ArrowBackIosNewOutlinedIcon  />
                         </div>
                    <div className={Styles.navitem}>   Back </div>
                   
                </Button>
                <Link to="/" >
                <Logo />
                </Link>

            </Nav>
            <Outlet />
        </Navbar>
    );
}

export default BackNavbar