import img1 from "../../../assets/images/Wholphin-Advantage/priority-to-functional-user-friendly-design.png";
import img2 from "../../../assets/images/Wholphin-Advantage/unwavering-emphasis-on-security.png";
import img3 from "../../../assets/images/Wholphin-Advantage/guiding-you-beyond-the-basics.png";
import img4 from "../../../assets/images/Wholphin-Advantage/smart-features.png";
import img5 from "../../../assets/images/Wholphin-Advantage/free-store-submission.png";
import img6 from "../../../assets/images/Wholphin-Advantage/on-time-on-budget.png";
import img7 from "../../../assets/images/Wholphin-Advantage/7.png";
export const fourthData = [
    {
      img: img1,
      txt: "Priority to functional, user-friendly design",
      desc: "There are several ways to create apps that get the job done, but only a few of them prioritize the user journey and an effective interface. At Wholphin Tech, we believe that one of the key differences between an app that is embraced and one that is forgotten lies in its design - and remember this at every step along the way.",
    },
    {
      img: img2,
      txt: "Unwavering emphasis on security",
      desc: "here’s a lot of emphasis given to app performance. However, as data security and privacy start to grab headlines around the world, security and governance play a pivotal role while conceptualizing apps - and it’s an aspect that we don’t take lightly.",
    },
    {
      img: img3,
      txt: "Guiding you beyond the basic",
      desc: "How do you foresee your app adapting to an environment where business goals upgrade and change? At Wholphin Tech, we engage in honest and informative discussions, providing you with options for future-driven app features and ideas. Whether it is customization of app permissions or suggestions for dynamic features, we believe that choices can empower to make better decisions.",
    },
    {
      img: img4,
      txt: "Smart features",
      desc: "Would it be advisable to integrate third party applications into the app? What social-interaction features would boost engagement? Having worked with a variety of customers across industries, we place before you specific, business-centric suggestions that will increase the reach of your application.",
    },
    {
      img: img5,
      txt: "Free store submission",
      desc: "We understand submitting the app to the store and getting it approved is a cumbersome process. Leave that to us. We will submit your mobile apps to the Play Store and App Store, ensuring it adheres to required standards to get approved.",
    },
    {
      img: img6,
      txt: "On time, on budget",
      desc: "IT efforts are notorious for stretching both costs and timelines. The Wholphin Tech methodology effectively guards against cost and schedule overruns through honest initial estimates, consistent communication, and options to adapt a flexi-resource model.",
    },
    {
      img: img7,
      txt: "expertize seniority",
      desc: "Hiring is a well-planned and carefully conducted activity here at Wholphin Tech. We are proud of our people, many of whom bring years of experience with them and sound expertise. Our developers are proficient in their tech skills and committed to their work principles.",
    },
  ];