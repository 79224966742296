
import { ReadMoreButton } from "../../reusable/ReadMoreBottun/ReadMoreButton";
import Styles from "./BlogSection.module.scss"
import { blogsData } from "./data"
import { BsPlayCircle } from "react-icons/bs"
import { Link, Outlet, useNavigate } from 'react-router-dom';


export default function BlogSection() {
    // const navigate = useNavigate();

    // const handleReadMoreClick = (blog) => {
    //     navigate(`/blog/${blog.id}`);
    //   };
    return (
        <section className={`${Styles.container}`}>


            <div className={`${Styles.project_section_heading} mb-lg-1 row`}>
                <h3>Our Latest Podcast</h3>
                <div className="col-md-6">
                    <p className="col-lg-9">Listen Technology Based Podcast Hosted By Wholphin Tech.
                    </p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <ReadMoreButton   >
                        View All
                    </ReadMoreButton >
                </div>
            </div>

            <div className={`${Styles.card_container}`}>
                {
                    blogsData && blogsData.map((item, index) => (
                        <div key={index}
                            style={{ backgroundImage: `url(${item.img})` }}
                            className={`${Styles.card_box}`}>
                            <div className={Styles.card_body}>
                                <h6 className={Styles.card_title}>{item.title}</h6>
                                <div>
                                    <h4 className={Styles.card_heading}> {item.heading.slice(0, 35)} ...</h4>
                                    <p className={Styles.card_desc}>{item.desc.slice(0, 40)} ...</p>
                                </div>
                            </div>
                            <div className={Styles.card_footer}>
                                <span ><BsPlayCircle className={Styles.card_footer_icon} /></span>

                                <Link to={`/blog/${item.id} `}>
                                    <ReadMoreButton >
                                        Learn more
                                    </ReadMoreButton>
                                </Link>
                            </div>
                        </div>
                    ))

                }
            </div>


        </section>
    )
}