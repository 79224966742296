
import Styles from "./ShareConatiner.module.scss"

import {FaFacebook, FaTwitter, FaLinkedinIn, FaLink} from "react-icons/fa"
export default function ShareConatiner() {
    return (
        <div className={`${Styles.conatiner}`}>

            <div className={Styles.share}>
                <p>Share</p>
                <span><FaFacebook /></span>
                <span><FaTwitter /></span>
                <span><FaLinkedinIn /></span>
                <span><FaLink /></span>
            </div>

        </div>
    )
}