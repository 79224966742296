import blockChainImg from "../../../assets/blogs/chainblock.webp"
import IoTImg from "../../../assets/blogs/iot-techonologe.webp"
import mobileAppImg from "../../../assets/blogs/mobile-apps.webp"
import aboutImg from "../../../assets/blogs/about-us.webp"

export const blogsData=[

{
    id:1,
    title:"Blockchain",
    heading:"Is BlockChain Technology Worth The Hype?",
    desc:"Unfolds The Revolutionary & Versatility Of Blockchain Technology",
    para:"Blockchain and Crypto are the talks of the town topic and emerging trend which is making a lot of buzz in the market. Blockchain is a system of recording information in a way that secures it from getting hacked or failure. With the shifting of the digital world, Blockchain has become an essential element. It brings decentralization to everything and has multiple advantages. Blockchain are divided into four parts: public, private, hybrid, and consortium. We have described how to develop a Blockchain application process and know what to follow. Find everything in our Blockchain podcast from development to deployment, function, complexity, cost, and much more regarding Blockchain applications.",
    img:blockChainImg,
    author:"",
    date:"2022",
},
{
    id:2,
    title:"IoT",
    // heading:"IoT Technology - A Future In Making OR Speculating?",
    heading:"Definitive Guide to Hire Unity3D Developers in 2023",
    desc:"Everything You Need To Know About IoT Technology",
    para:"IoT is the emerging technology that is embedded with sensors, software, and communication modules. It helps a network of objects or devices connect through the internet. With IoT, communicating with other similar devices has become easier. IoT has three categories- consumer IoT applications, Industrial applications, and Organizational applications. IoT technology is considered the next future technology that will transform human lives, but is it so? Is it a fact or just a speculation?",
    img:IoTImg,
    author:"",
    date:"2023",
},
{
    id:3,
    title:"Mobile Apps",
    heading:"Flutter Vs. React Native - Choose Best One",
    desc:"Unfolding The Confusion Of Which One Is Better",
    para:" Flutter v/s React Native is an all time debatable question. In this podcast, Wholphin tech clears the concept and highlights the points for everyone who is confused about which one to choose for developing. Both are open-source platforms, React Native‘s architecture is composed of React code; Flutter uses Dart language for programming, and React Native uses JavaScript- both of them are easily adopted and preferable by developers for different purposes. However, React Native’s JavaScript has sets and libraries are five times larger than Flutter’s Dart. What are scalability and maintenance of the flutter and React Native, which program provides better User Experience, and so on?",
    img:mobileAppImg,
    author:"",
    date:"2021",
},
{
    id:4,
    title:"About us",
    heading:"Journey of Wholphin tech",
    desc:"From Startup To Successful Thriving IT Provider",
    para:" Wholphin tech is a leading mobile and web application company in the USA and India. The company's journey is commendable; from being a quintessential startup to no one mobile and web applications, Wholphin tech has become the most trusted name in the industry. In the past 10 years, the company has witnessed tremendous growth and success. In 2011, Mr Harnil Oza, founder of Wholphin tech, started with an idea. Later, with brilliant vision and talent, Mr Oza and his team marked their presence in the web and mobile application industry worldwide.",
    img:aboutImg,
    author:"",
    date:"2018",
}

]