// export const process =[
//     {
//       title: "Faster Development",
//       txt: "Be it a mobile app or a website.We develop the software at a 50 % faster rate by using Hybrid technologies, and expertise of 4+ years.",
//     },
//     {
//       title: "Multi Platform",
//       txt: "Our products are build in android , I.O.S and web technologies simultaneously to achieve faster product release .",
//     },
//     {
//       title: "Trending technologies",
//       txt: "Our products are equipped with trending technologies like geo-location, offline navigation, offline applications, Push notifications, integrated sms and online payments,Firebase. Chat and large data transfer capabilities,image Optimization etc.",
//     },
//     {
//       title: "Creative",
//       txt: "Our developers ultimate objective while building any product is not just productivity, services or content, but to provide the best user experience. Users love to install and regularly use products that give them a pleasant experience.",
//     },
//     {
//       title: "Light Code",
//       txt: "We try to write the code in a simple and light way to provide the client with better understanding for the future use.",
//     },
//   ]


export const process =[
  {
    id:1,
    title: "Requirement Gathering",
    txt: "We follow the first and foremost priority of gathering requirements, resources, and information to begin our project.",
  },
  {
    id:2,
    title: "UI/UX Design",
    txt: "We create catchy and charming designs with the latest tools of designing to make it a best user-friendly experience.",
  },
  {
    id:3,
    title: "Prototype",
    txt: "After designing, you will get your prototype, which will be sent ahead for the development process for the product.",
  },
  {
    id:4,
    title: "Development",
    txt: "Development of mobile application/web/blockchain started using latest tools and technologies with transparency.",
  },
  {
    id:5,
    title: "Quality Assurance",
    txt: "Wholphin Tech values quality and provides 100% bug free application with no compromisation in it.",
  },
  {
    id:6,
    title: "Deployment",
    txt: "After trial and following all processes, your app is ready to launch on the App store or Play Store.",
  },
  {
    id:7,
    title: "Support & Maintenance",
    txt: "Our company offers you all support and the team is always ready to answer every query after deployment.",
  },


]