
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import Styles from "./ReadMoreButton.module.scss"


export function ReadMoreButton(props) {
    const Styles1 = {
      // maxWidth: props.width ? props.width : "130px",
      // Width: props.width ? props.width : "inherit",
      minWidth: props.width ? props.width : "inherit",
      height: props.height ? props.height : "inherit",
      background: props.bg ? props.bg : "var(--bg_primary1)",
      color: props.color ? props.color : "var(--font_primary2)",
      outline:"0.1px solid grey"
   

    };
  
    return (
      <div style={Styles1} className={Styles.container} onClick={props.handleClick}>
      {props.children}  <span className='ms-md-3'><EastOutlinedIcon /></span>
      </div>
    );
  }
  