import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';

const AOSWrapper = ({ children }) => {
  useEffect(() => {
    AOS.init({
        once: true
    });
    AOS.refresh();
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    console.log("App component mounted");
    window.scrollTo(0, 0);
  }, [pathname]);


  return <div>{children}</div>;
};

export default AOSWrapper;
