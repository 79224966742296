import Styles from "./ScreenShotsCarousel.module.scss"
import Theme from "../../../styles/Theme.module.scss"
import { data } from "./dataImgages"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
const ScreenShotsCarousel = () => {
    return (
        <section className={Styles.container}>

            <div className="row justify-content-between ps-lg-5 pb-lg-4">
                <div className="col-12 col-md-5">
                    <div className={Theme.section_sub_box}>
                        <div className={` ${Theme.section_sub_bar}`}></div>
                        <div className={`${Theme.section_sub_title}`}>Screenshots</div>
                    </div>
                    <div className={`${Theme.section_title} mt-3`}>
                    A Picture Is Worth a Thousand Words
                    </div>
                </div>
            </div>
            {/* <h6 className={`m-1 text-center ${Theme.section_title} `} >Screenshots </h6>
            <p className={`mb-3 text-center ${Theme.section_desc}`}>A picture is worth a thousand words</p> */}

            <Swiper
                spaceBetween={3}
                slidesPerView={"auto"}
                loop={true}
                centeredSlides={true}
                modules={[Autoplay]}
                autoplay={{
                    "delay": 2500,
                    "disableOnInteraction": false
                }}
                speed={3000}

            >
                {data && data.map((item, index) => (
                    // <div key={index} >
                    <SwiperSlide className={`${Styles.swiper_slide}`} >
                        <img src={item} className={Styles.image} />
                    </SwiperSlide>
                    // </div>
                ))}
            </Swiper>
        </section>
    )
}

export default ScreenShotsCarousel