import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./sliders.css";
import Styles from "./projectSection.module.scss"
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

export function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <MdArrowForwardIos className="arrow-btn" onClick={onClick} />
    </div>
  );
}
export function SampleNextprev(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <MdArrowBackIos className="arrow-btn" onClick={onClick} />
    </div>
  );
}

export default function SliderComponent(props) {
  const { img } = props;



  var settings = {
    speed: 500,

    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: <SampleNextprev />,
    nextArrow: <SampleNextArrow />,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={`${Styles.modal_slider_container}`}>
      <Slider {...settings} className={`${Styles.modal_img_slider}`}>
        {img?.map((ele, index) => (
          <div key={index} className={`${Styles.modal_img_card}`}>
            <img  src={ele} alt={ele.img} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
