import React, { useEffect, useRef, useState } from 'react';
import Theme from "../../../styles/Theme.module.scss"
import Styles from "./SecondSection.module.scss"
import home from "../../home/Index.module.scss";
import { Link, NavLink } from 'react-router-dom';
import { cards } from "./data"
import { scrollToSection } from '../navbar/Navbar';

// images
import img1 from "../../../assets/images/mobile-app-development/mob-1.jpg"
import img2 from "../../../assets/images/mobile-app-development/mob-2.jpg"
import img3 from "../../../assets/images/mobile-app-development/mob-3.jpg"

export default function SecondSection() {
  const [scrolY, setScrolY] = useState(false);


  return (
    <div className={`${Styles.mobile_container}`}>

      <div
        data-aos="fade-up"
        data-aos-duration="2000"

        className={`row p-lg-5 my-5 ${Theme.align} `}
      >
        <div className="col-6 "
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <div className="row align d-none d-md-flex">
            <div className="col-5 px-4 m-auto">
              <div className="row ">
                <div
                  onMouseOver={() => setScrolY(false)}
                  className={`col-12 overflow-hidden rounded ${home.img_effect_div} mt-5`}
                >
                  <img
                    id="img"
                    src={img1}
                    className={`${scrolY ? "w-100" : `w-100 ${home.img_effect}`
                      }`}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-6 px-4">
              <div className="row">
                <div
                  onMouseOver={() => setScrolY(false)}
                  className={`col-12 overflow-hidden rounded ${home.img_effect_div1}`}
                >
                  <img
                    id="img1"
                    src={img2}
                    className={scrolY ? "w-100" : `w-100 ${home.img_effect}`}
                    alt=""
                  />
                </div>
                <div
                  onMouseOver={() => setScrolY(false)}
                  className={`col-10 overflow-hidden rounded mt-3  ${home.img_effect_div2}`}
                >
                  <img
                    id="img2"
                    src={img3}
                    className={scrolY ? "w-100" : `w-100 ${home.img_effect}`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          onMouseOver={() => setScrolY(false)}
          className={`col-12 overflow-hidden rounded ${home.img_effect_div3} d-md-none mb-3`}
        >
          <div className={`${home.img_effect} `}>
            <div style={{ backgroundColor: "white", paddingBottom: "110px", height: "300px", borderRadius: "30px" }}>
              <img
                id="img3"
                src={img1}
                className={scrolY ? "w-100" : `w-100 ${home.img_effec}`}
                alt=""
              />
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="2000"
          className="col-12 col-md-6 pt-md-3 px-lg-5 px-4">
          <div>
            <div className={Theme.section_sub_box}>
              <div className={` ${Theme.section_sub_bar}`} />
              <div className={` ${Theme.section_sub_title}`}>
                Software Development
              </div>
            </div>
            <div className={`col-md-8 my-3 ${Theme.section_title}`}>
              Our Software Development Services.
            </div>
            <div className={`${Theme.section_desc} pe-3`}>
              With  developers and expertise spanning a wide range of
              technologies, we hold a strong track record in the following:
            </div>
          </div>
          <div className="row mt-3">
            {cards.map((item, index) => {
              return (
                <div
                  className={` col-6 col-md-6 col-lg-4 my-2 my-lg-1`}
                  key={index}
                >
                  <div className={`${home.card_shadow} ${Styles.card_box} `}>
                    <item.icon className={`${Styles.card_icon}`} />
                    <div className={`${Styles.card_title}`}>
                      {item.txt}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={`d-flex align mt-3  ${Theme.align}`}>

              <Link href="#techStack"
                onClick={() => { scrollToSection("techStack") }}
                className={`${Theme.btn_effect} ${Styles.link} me-3`} >
                Tech Stack
              </Link>

              <Link 
              onClick={() => { scrollToSection("contact") }}
              className={`${Theme.btn_effect_2} ${Styles.link}`}>
                Get a Quote
              </Link>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
