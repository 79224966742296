import React, { useEffect, useState } from "react";
import Styles from "./projectSection.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { products1 } from "./data";

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderComponent, { SampleNextArrow, SampleNextprev } from "./SliderComponent";
import { Link, useNavigate } from "react-router-dom";
import ProjectLandingPage from "./ProjectLandingPage";
import { ReadMoreButton } from "../../reusable/ReadMoreBottun/ReadMoreButton";

export default function ProjectSection() {
  const [selectedId, setSelectedId] = useState(false);
  const navigate = useNavigate()

  var settings = {
    speed: 1400,
    slidesToShow: 3,
    // slidesPerRow: 2,
    rows: 2,
    slidesToScroll: 3,
    initialSlide: 0,
    prevArrow: <SampleNextprev />,
    nextArrow: <SampleNextArrow />,
    dots: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          autoplay: true,
          nextArrow: false,
          prevArrow: false,
          // infinite: true,
        },
      },
    ],
  };

  function navigationFunction(item) {
    navigate(`./project_details/${item.id}`)
  }
  return (
    <div className={`${Styles.project_section_container} `}>
      <div className="p-lg-5 pt-4"
        data-aos="zoom-out"
        data-aos-duration="2000"
      >
        <motion.div className={`${Styles.project_section_heading} mb-lg-3 row`}>
          <h3>Our Projects</h3>
          <div className="col-md-6">
            <p className="col-lg-9">We shed a light on our work and what goes behind the development.</p>
          </div>
          {/* <label className={`${Styles.read_more_btn} col-lg-6 mx-3`}> */}
          <div className="col-md-6 d-md-flex justify-content-end text-end">
          <Link to="/projects" >
            <ReadMoreButton>
            View All    
            {/* <span className="m-0 p-0" >< EastOutlinedIcon /></span> */}
            </ReadMoreButton>
            </Link>
          </div>
       
        </motion.div>

        <Slider {...settings}>
          {products1 &&
            products1?.map((item, index) => (
              <motion.div
                layoutId={item.id}
                key={index}
                onClick={() => navigationFunction(item)}
                className={`${Styles.project_cards1} `} >
                <div className={`${Styles.card_body1}`}>
                  <div className={`${Styles.mobile}`}>
                    <img src={item.mobile} className={`${Styles.mobile_img}`} />
                  </div>
                  <div className={`${Styles.card_log1}`}>
                    <img className={`${Styles.logo}`} src={item.logo} />
                    <div className={`${Styles.card_title}`}>
                      {item.name}
                    </div>
                  </div>
                </div>
                <div className={`px-3 py-4 ${Styles.card_footer1}`}>
                  <div>
                    <div className={`${Styles.footer_desc}`}>{item.title}</div>
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    {item.tech.map((item1, index) => (<span key={index} className={`${Styles.tech_chip}`}>{item1}</span>))}
                  </div>
                  {/* <div className={`${Styles.visits}`}>
                    <div>{item.visit}</div>
                    <div>{item.download}</div>
                  </div> */}
                </div>

              </motion.div>
            ))}
        </Slider>
      </div>

    </div>
  );
}
