import React from "react";
import Styles from "./banner.module.scss";
import Theme from "../../../styles/Theme.module.scss";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { scrollToSection } from "../navbar/Navbar";
import bannerImg from "../../../assets/images/banner-image.png"

export default function FirstBanner() {
    return (
        <div className={`${Styles.banner_container}`}>
            <div
                data-aos="zoom-out"
                data-aos-duration="2000"
                className={`container-fluid ${Styles.container_fluid} `}>
                <div className={`row ${Styles.row_we1}`}>
                    <motion.div className="col-md-6 px-lg-5">
                        <div className={`${Styles.d_flex1}  `}>
                            <div className={`${Styles.welcome_text}`}>

                                <h1 data-aos="fade-left">Your Full-Stack Digital Transformation Partner</h1>

                                <p>From Mobile Apps to Custom Software Solutions, our design-led,
                                    tech-driven approach to development help businesses scale faster
                                    and deliver more value to their stakeholders.</p>

                                <div className={`d-flex align-items-start ${Theme.justify_c} mt-3  justify-content-lg-start mt-md-5`}>
                                    <Link href="#services" onClick={() => { scrollToSection("services") }}
                                        className={`${Theme.btn_effect_2} me-2 me-lg-3`}>
                                        <span>Our Services</span>
                                    </Link>
                                    <Link onClick={() => { scrollToSection("contact") }}>
                                        <span
                                            className={`${Theme.btn_effect}  `}
                                        >
                                            Discuss Requirement
                                        </span>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </motion.div>

                    <div className={`col-md-6`}>
                        {/* <motion.div className="mt-lg-5 d-flex justify-content-end"  >
                            <motion.img
                                className={`animate__animated  ${Styles.image} `}
                                animate={{ rotate: 360 }}
                                transition={{
                                    //   repeat: 1,
                                    //   repeatType: "reverse",
                                    duration: 4
                                }}
                                src="../images/wholphin-logo-icon.png"
                            />
                        </motion.div> */}
                        <div className={Styles.d_flex1}>
                        <motion.div className=""  >
                            <motion.img src={bannerImg}   className={`  ${Styles.image1} `}  />
                       </motion.div>
                       </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
