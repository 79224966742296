import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.module.scss";
import Footer from "./Pages/footer/Footer";

import RoutesComponent from "./Routes/RoutesComponent";


function App() {
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   console.log("App component mounted");
  //   window.scrollTo(0, 0);
  // }, [pathname]);


  return (
    <div className="">
      {/* <MyNavbar /> */}
      {/* <BlogLandingPage /> */}

 
      <RoutesComponent />
      <Footer />
    </div>
  );
}

export default App;
