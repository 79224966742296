import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Styles from "./Clients.module.scss";
import Theme from "../../../styles/Theme.module.scss"

import { SampleNextArrow, SampleNextprev } from "../projects/SliderComponent";
import { clientsImages } from "./data";


export default function Clients() {

  var settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1500,
    slidesToShow: 7,
    slidesPerRow: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextprev />,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,

        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          dots: false,
        },
      },
    ],
  };
  return (
    <div 
    
    className={`${Styles.client_slider_container}`}>
      <div
        // data-aos="zoom-out"
        // data-aos-duration="2000"
        // data-aos-anchor-placement="top-bottom"
        className="row px-lg-5 px-3 pt-5 mb-2 justify-content-between">
        <div className="col-12 col-md-5 ps-lg-5"

        >
          <div className={Theme.section_sub_box}>
            <div className={` ${Theme.section_sub_bar}`}></div>
            <div className={`${Theme.section_sub_title}`}>CLIENTS</div>
          </div>
          <div className={`mt-2 ${Theme.section_title} `}>
            Partnerships Across Domains
          </div>
        </div>
        <div

          className={` col-12 col-md-7 pe-lg-5 ${Theme.section_desc} `}>
          Through the years, we are grateful to have worked with several
          distinguished clients across verticals, having slowly built a
          reputation for quality and expertise in the process.
        </div>
      </div>

      <div className="my-lg-4 pt-4">
        <Slider {...settings} className={`${Styles.client_slider}`}>
          {clientsImages?.map((ele, index) => (
            <div key={index} className={`${Styles.client_slider_card}`} >
              <img className={`${Styles.client_slider_img}`} src={ele.img} alt={ele.img} />
            </div>
          ))}
        </Slider>
      </div>
      {/* <div className="Styles.client_slider_card1" >
        {clientsImages?.map((ele, index) => (
          <div key={index} className="image-box" >
            {
              (index < 9) && <img className="Styles.client_slider_img animated-right" src={ele.img} alt={ele.img} />
            }
          </div>
        ))}
      
      </div>

      <div className="Styles.client_slider_card1" >
       
          {clientsImages?.map((ele, index) => (
            <div key={index} className="" >
              {
                (index >= 9) && <img className="Styles.client_slider_img animated-left" src={ele.img} alt={ele.img} />
              }

            </div>
          ))}
      
      </div> */}

    </div>
  );
}
