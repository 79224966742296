import {SiAndroid, SiApple,SiGooglechrome} from "react-icons/si";
import {TbDeviceAnalytics} from "react-icons/tb";
import {FcMultipleDevices} from "react-icons/fc";
import {CgWebsite} from "react-icons/cg";
import {GrCloudSoftware} from "react-icons/gr";
import {TiCloudStorageOutline} from "react-icons/ti";
// import {PiDevices} from "react-icons/pi"


export const cards = [
    { icon: SiAndroid , txt:"Android Development"},
    { icon: SiApple , txt:"IOS Development"},
    { icon: CgWebsite , txt: "Web app Development  " },
    { icon: FcMultipleDevices, txt: "Desktop app Development" },
    { icon: TiCloudStorageOutline, txt: "Full Stack Web & Mobile" },
    { icon: SiGooglechrome, txt: "PWA app development" },
];